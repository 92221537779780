import React from 'react';

import {Route, Routes, BrowserRouter} from 'react-router-dom'

// Import VIEWS
import HomeView from "../views/HomeView"

const Router = () => {
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomeView />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Router;