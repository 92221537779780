import React, { useState } from 'react';
import './TaskFormComponent.scss';

// API
import apiService from '../../services/apiService';

// Contexts
import { useBoard } from '../../contexts/BoardContext';
import { useTheme } from '../../contexts/ThemeContext';

//DomPurify
import DOMPurify from 'dompurify';


const TaskFormComponent = ({ modalProps, existingTask, isEditingMode }) => {
  const { theme } = useTheme();
  const { board, dispatch } = useBoard();

  const [error, setError] = useState("");
  const [taskTitle, setTaskTitle] = useState(isEditingMode ? existingTask.title : "");
  const [taskStatus, setTaskStatus] = useState(isEditingMode ? existingTask.column_id : board.columns[0].id);
  const [taskDescription, setTaskDescription] = useState(isEditingMode ? existingTask.description : "");
  const [subtasks, setSubtasks] = useState(isEditingMode ? existingTask.subtasks : [{ "title": "" }]);


  const [editedSubtasks, setEditedSubtasks] = useState(isEditingMode ? JSON.parse(JSON.stringify(existingTask.subtasks)) : []);
  const [editedTaskTitle, setEditedTaskTitle] = useState(isEditingMode ? existingTask.title : "");
  const [editedTaskDescription, setEditedTaskDescription] = useState(isEditingMode ? existingTask.description : "");
  const [editedTaskStatus, setEditedTaskStatus] = useState(isEditingMode ? existingTask.column_id : board.columns[0].id);
  const [subtasksToDelete, setSubtaskToDelete] = useState([]);


  // Validation
  const validChars = (name) => {
    const regex = /^[a-zA-Z0-9 ]*$/;
    return regex.test(name);
  };

  const handleSetTaskTitle = (value) => {
    const sanitizedInput = DOMPurify.sanitize(value);
    if (validChars(sanitizedInput)) {
      setError("")
      if (!isEditingMode) {
        setTaskTitle(sanitizedInput);
      } else {
        setEditedTaskTitle(sanitizedInput);
      }
    } else {
      setError("Task title can only have letters, numbers and spaces.")
    }

  }

  const handleInputChange = (event, index, inputValue) => { //subtasks
    const sanitizedInput = DOMPurify.sanitize(event.target.value);
    if (validChars(sanitizedInput)) {
      setError("")
      if (!isEditingMode) {
        const updatedSubtasks = [...subtasks];
        setError("");
        if (updatedSubtasks.some(input => input.title === inputValue)) {
          setError("There cannot be duplicated subtasks.")
        }
        updatedSubtasks[index].title = sanitizedInput;
        setSubtasks(updatedSubtasks);
      } else {
        const updatedSubtasks = [...editedSubtasks];
        setError("");
        if (updatedSubtasks.some(input => input.title === inputValue)) {
          setError("There cannot be duplicated subtasks.")
        }
        updatedSubtasks[index].title = event.target.value;
        setEditedSubtasks(updatedSubtasks);
      }
    } else {
      setError("Task names can only have letters, numbers and spaces.")
    }
  }

  const handleDeleteInput = (inputName) => {
    if (!inputName) return;
    if (!isEditingMode) {
      const indexToDelete = subtasks.findIndex(input => {
        return input.title && input.title.trim().toLowerCase() === inputName.trim().toLowerCase();
      });
      if (indexToDelete !== -1) {
        const filteredArray = [...subtasks];
        filteredArray.splice(indexToDelete, 1);
        setSubtasks(filteredArray);
      }
    } else {
      const indexToDelete = editedSubtasks.findIndex(input => {
        return input.title && input.title.trim().toLowerCase() === inputName.trim().toLowerCase();
      });
      if (indexToDelete !== -1) {
        const filteredArray = [...editedSubtasks];
        filteredArray.splice(indexToDelete, 1);
        setEditedSubtasks(filteredArray);
        setSubtaskToDelete(prevSubtasks => [...prevSubtasks, editedSubtasks[indexToDelete]])
        return filteredArray;
      }

    }
  };

  const handleAddInput = () => { //subtasks
    setError("")
    if (!isEditingMode) {
      setSubtasks([...subtasks, { "title": "" }])
    } else {
      setEditedSubtasks([...editedSubtasks, { "title": "" }])

    }
  }

  const handleSelectChange = (event) => { //status
    if (!isEditingMode) {
      setTaskStatus(event);
    } else {
      setEditedTaskStatus(event)
    }
  }

  const handleTextareaChange = (event) => { //descriptio
    const sanitizedInput = DOMPurify.sanitize(event);
    if (!isEditingMode) {
      setTaskDescription(sanitizedInput);
    } else {
      setEditedTaskDescription(sanitizedInput)
    }
  }

  const handleAddNewTask = async () => {
    const taskData = await apiService.addNewTask(taskTitle, taskDescription, taskStatus);
    if (taskData.id) {
      const subtasksWithTaskId = subtasks.map((subtask) => ({ ...subtask, "task_id": taskData.id }));
      for (const subtask of subtasksWithTaskId) {
        const subtaskData = await apiService.addNewSubtasks(subtask);
      }
      const updatedColumns = [...board.columns];
      const columnIndex = updatedColumns.findIndex(column => column.id === Number(taskStatus));
      if (columnIndex !== -1) { // Not found
        updatedColumns[columnIndex] = {
          ...updatedColumns[columnIndex],
          tasks: [...updatedColumns[columnIndex].tasks, taskData]
        };
      }
      dispatch({ type: 'SET_BOARD', payload: { ...board, columns: updatedColumns } });
      modalProps.handleToggleModal('addTask');
    }
  }

  const handleEditTask = async () => {
    // Change task title
    if (taskTitle !== editedTaskTitle) {
      const taskData = await apiService.updateTask(existingTask.id,
        { "title": editedTaskTitle });
      if (taskData.id) {
        dispatch({ type: 'UPDATE_TASK_NAME', payload: taskData });
      }
    }
    // Change task description
    if (taskDescription !== editedTaskDescription) {
      const taskData = await apiService.updateTask(existingTask.id,
        { "description": editedTaskDescription });
      if (taskData.id) {
        dispatch({ type: 'UPDATE_TASK', payload: taskData });
      }
    }

    // Change task status
    if (taskStatus !== editedTaskStatus) {
      const taskData = await apiService.updateTask(existingTask.id,
        { "column_id": editedTaskStatus });
      if (taskData) {
        dispatch({
          type: 'CHANGE_TASK_COLUMN',
          payload: { taskId: Number(taskData.id), columnId: Number(taskData.column_id) }
        })
      }
    }

    // Change subtasks
    // Change title
    for (const editedSubtask of editedSubtasks) {
      const taskToCompare = subtasks.find(subtask => subtask.id === editedSubtask.id);
      if (taskToCompare && taskToCompare.title !== editedSubtask.title) {
        const subtaskData = await apiService.updateSubtask(editedSubtask.id,
          { "title": editedSubtask.title });

        if (subtaskData.id) {
          dispatch({
            type: 'UPDATE_SUBTASK',
            payload: subtaskData
          })
        }
      }
    }

    // Add new subtasks
    for (let i = 0; i < editedSubtasks.length; i++) {
      if (!editedSubtasks[i].id) {
        const subtaskData = await apiService.addNewSubtasks({ "task_id": existingTask.id, "title": editedSubtasks[i].title, "completed": false });
        if (subtaskData.id) {
          dispatch({ type: 'ADD_NEW_SUBTASK', payload: subtaskData })
        }
      }
    }

    // Delete subtask
    if (subtasksToDelete.length > 0) {
      for (const subtask of subtasksToDelete) {
        const subtaskData = await apiService.deleteSubtask(subtask.id);
        if (subtaskData.message === "Subtask deleted successfully") {
          dispatch({ type: 'DELETE_SUBTASK', payload: subtask });
        }
      }
    }
    modalProps.onClose()
  }

  return (
    <div className="AddNewTaskFormComponent d-flex flex-column">
      <div className="mb-3">
        <label htmlFor="taskTitle" className="form-label">Title</label>
        <input
          value={isEditingMode ? editedTaskTitle : taskTitle}
          type="text" className="form-control" id="taskTitle" placeholder="e.g. Take Coffee Breaks"
          onChange={(event) => handleSetTaskTitle(event.target.value)}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="taskDescription" className="form-label">Description</label>
        <textarea
          value={isEditingMode ? editedTaskDescription : taskDescription}
          onChange={(event) => handleTextareaChange(event.target.value)}
          type="text" className="form-control" id="taskDescription" placeholder="e.g. It's always good to take a break. This 15 minute break will reharge the batteries a little."
        />
      </div>
      <p className="fw-bold">Subtasks</p>
      {(isEditingMode ? editedSubtasks : subtasks)
        .map((input, index) => {
          return (
            <div className="input-group mb-3" key={index}>
              <input type="text" className="form-control"
                value={isEditingMode ? input.title : input.title}
                onChange={(event) => handleInputChange(event, index, event.target.value)}
              />
              <span className="btn" type="button"
                onClick={
                  () => {
                    handleDeleteInput(input.title);
                  }}
              >X</span>
            </div >
          )
        })
      }
      <button className={`${theme} btn button-secondary mb-2`} onClick={handleAddInput}>+ Add New Subtask</button>
      {
        error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )
      }
      <div className="mb-3">
        <label htmlFor="column" className="form-label">Status</label>
        <select className="form-select" aria-label="column"

          value={isEditingMode ? editedTaskStatus : taskStatus}
          onChange={

            (event) => handleSelectChange(event.target.value)
          }
        >
          {board.columns.map((column) =>
            <option key={column.id} value={column.id}>{column.name}</option>
          )}
        </select>
      </div>

      <button className='btn button-primary'
        onClick={isEditingMode ? () => handleEditTask() : () => handleAddNewTask()}
        disabled={error !== "" || !taskTitle}
      >
        {!isEditingMode ? "Add New Task" : "Edit Current Task"}
      </button>
    </div >
  )
};

export default TaskFormComponent;