import React, { createContext, useContext, useState } from "react";

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState('dark');

    const handleToggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    };

    const ThemeState = {
        theme,
        handleToggleTheme,
    }

    return (
        <ThemeContext.Provider value={ThemeState}>
            {children}
        </ThemeContext.Provider>
    );
}

export default ThemeProvider;

//Custom useTheme hook
export const useTheme = () => useContext(ThemeContext);