import React, { useState } from 'react';

// Contexts
import { useTheme } from './contexts/ThemeContext';


// Styles
import "./App.scss";
import 'bootstrap/dist/css/bootstrap.min.css';

import 'bootstrap/dist/js/bootstrap.bundle.min';

// Components
import AsideComponent from "./components/Aside/AsideComponent";
import HeaderComponent from "./components/Header/HeaderComponent";
import CustomModal from './components/CustomModal/CustomModal';
import AsideMenuComponent from './components/Aside/AsideMenuComponent/AsideMenuComponent';

// Routes
import Router from './router/Router';
import AsideProvider, { useAside } from './contexts/AsideContext';


import iconHideSidebar from "./assets/icon-hide-sidebar.svg"
import iconShowSidebar from './assets/icon-show-sidebar.svg';

function App() {
  const { isCollapsed, toggleCollapse } = useAside();
  const { theme } = useTheme();

  const [show, setShow] = useState(false);

  const handleToggleModal = () => setShow(prevShow => !prevShow);

  const handleCloseParentModal = () => {
    setShow(false);
  };
  return (
    <div className={`App ${theme} wrapper`}>
      <button className={`btn button-primary toggleCollapseButton px-5 py-3 ${isCollapsed ? "position-fixed" : "d-none"}`} onClick={toggleCollapse}>
        <img src={isCollapsed ? iconShowSidebar : iconHideSidebar} alt="Show Aside" className='me-1 ' />
      </button>
      <button className="btn button-primary toggleCollapseButton px-5 py-3 position-fixed d-block d-sm-none" onClick={handleToggleModal} alt="Show Aside" >
        <img src={isCollapsed ? iconShowSidebar : iconHideSidebar} alt="Show Aside" className='me-1' />
      </button>
      <div className={`${theme} collapse collapse-horizontal  ${isCollapsed ? '' : 'show'}`} id="Aside">
        <AsideComponent />
      </div>
      <div className={`main ${theme} `}>
        <div className="header">
          <HeaderComponent />
        </div>
        <div className="content">
          <Router />
        </div>
      </div>
      <CustomModal
        show={show}
        onHide={handleToggleModal}
        title="All Boards"
      >
        <AsideMenuComponent handleCloseParentModal={handleCloseParentModal} modal={true} />
      </CustomModal>
    </div >

  );
}

export default App;
