import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const getAllBoards = async () => {
    try {
        const response = await axios.get(`${API_URL}/boards/`);
        return response.data;
    } catch (error) {
        console.error("Failed to get All Boards", error);
    }
}

const getBoardById = async (board_id) => {
    try {
        const response = await axios.get(`${API_URL}/boards/${board_id}`);
        return response.data;
    } catch (error) {
        console.error("Failed to get BoardByID", error);

    }
}

const getFirstBoard = async () => {
    try {
        const response = await axios.get(`${API_URL}/boards/first/`);
        return response.data;
    } catch (error) {
        console.error("Failed to get the first board", error);

    }
}

const updateSubtask = async (subtask_id, updatedData) => {
    try {
        const response = await axios.put(`${API_URL}/subtasks/${subtask_id}`,

            updatedData,

            {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

        const data = response.data;
        return data;

    } catch (error) {
        console.error("Failed to update subtask", error);
    }
}

const countCompletedSubtasks = async (task_id) => {
    try {
        const response = await axios.get(`${API_URL}/subtasks/count_completed/${task_id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        return response.data;
    } catch (error) {
        console.error("Failed to count completed subtasks", error);

    }
}

const updateTask = async (task_id, updatedData) => {
    try {
        const request = await axios.put(`${API_URL}/tasks/${task_id}`,
            updatedData, // title, description, column_id, subtasks
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        const data = request.data;
        return data;
    } catch (error) {
        console.error("Failed to update task", error);
    }
}

const addNewBoard = async (boardName) => {
    try {
        const request = await axios.post(`${API_URL}/boards/`,
            { "name": boardName },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
        const data = request.data;
        return data;
    } catch (error) {
        console.error("Failed to add a new board", error);
    }
}

const addNewColumns = async (columnsObject) => {
    try {
        const request = await axios.post(`${API_URL}/columns/`,
            columnsObject,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
        const data = request.data;
        return data;
    } catch (error) {
        console.error("Failed to add columns", error);

    }
}

const deleteColumn = async (column_id) => {
    try {
        const request = await axios.delete(`${API_URL}/columns/${column_id}`)
        const data = request.data;
        return data;
    } catch (error) {
        console.error("Failed to delete column", error);
    }
}


const deleteTask = async (task_id) => {
    try {
        const request = await axios.delete(`${API_URL}/tasks/${task_id}`)
        const data = request.data;
        return data;
    } catch (error) {
        console.error("Failed to delete task", error);
    }
}

const deleteSubtask = async (subtask_id) => {
    try {
        const request = await axios.delete(`${API_URL}/subtasks/${subtask_id}`)
        const data = request.data;
        return data;
    } catch (error) {
        console.error("Failed to delete subtask", error);
    }
}

const deleteBoard = async (board_id) => {
    try {
        const request = await axios.delete(`${API_URL}/boards/${board_id}`)
        const data = request.data;
        return data;
    } catch (error) {
        console.error("Failed to delete board", error);
    }
}

const addNewTask = async (taskTitle, taskDescription, taskStatus) => {
    try {
        const request = await axios.post(`${API_URL}/tasks/`,
            {
                "title": taskTitle,
                "description": taskDescription,
                "column_id": taskStatus
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        const data = request.data;
        return data;
    } catch (error) {
        console.error("Failed to add task", error);

    }
}


const addNewSubtasks = async (subtasksObject) => {
    try {
        const request = await axios.post(`${API_URL}/subtasks/`,
            subtasksObject,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
        const data = request.data;
        return data;
    } catch (error) {
        console.error("Failed to add subtasks", error);

    }
}

const editExistingBoard = async (board_id, newBoardName) => {
    try {
        const request = await axios.put(`${API_URL}/boards/` + board_id,
            {
                "name": newBoardName
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        const data = request.data;
        return data;
    } catch (error) {
        console.error("Failed to edit current board", error);
    }
}

const editExistingColumn = async (column_id, newColumnName) => {
    try {
        const request = await axios.put(`${API_URL}/columns/` + column_id,
            {
                "name": newColumnName
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
        const data = request.data;
        return data;
    } catch (error) {
        console.error("Failed to edit current column", error);

    }
}


const apiService = {
    getAllBoards,
    getBoardById,
    getFirstBoard,
    updateSubtask,
    updateTask,
    addNewBoard,
    addNewColumns,
    deleteColumn,
    deleteBoard,
    addNewTask,
    addNewSubtasks,
    editExistingBoard,
    editExistingColumn,
    countCompletedSubtasks,
    deleteSubtask,
    deleteTask

};

export default apiService;