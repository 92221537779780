import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
import apiService from "../services/apiService";


// Initial State
const initialState = {
    boards: [],
}

// The reducer function takes two arguments: the current state and an action, and returns a new state.
const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_ASIDE_WITH_BOARD_NAMES':
            return { ...state, boards: action.payload };
        case 'UPDATE_ASIDE_WITH_BOARD_NAMES':
            return { ...state, boards: [...state.boards, action.payload] };
        case 'DELETE_BOARD_FROM_ASIDE':
            return {
                ...state,
                boards: state.boards.filter(board => board.id !== action.payload)
            };
        case 'UPDATE_ASIDE_WITH_NEW_BOARD_NAME':
            return {
                ...state,
                boards: state.boards.map(board =>
                    board.id === action.payload.id ?
                        { ...board, name: action.payload.name } :
                        board
                )
            };
        default:
            return state;
    }
}

// Create the context
export const AsideContext = createContext();

// Providing Context

const AsideProvider = ({ children }) => {
    // usereducer is more complex that useState
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const fetchData = async () => {
            const data = await apiService.getAllBoards();
            dispatch({ type: 'SET_ASIDE_WITH_BOARD_NAMES', payload: data })
        };

        fetchData();
    }, []);

    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleCollapse = () => {
        setIsCollapsed(prevState => !prevState);
    };


    return (
        <AsideContext.Provider value={{ boards: state.boards, dispatch, isCollapsed, toggleCollapse }}>
            {children}
        </AsideContext.Provider>
    );
}

export default AsideProvider;

// Custom hook to consume the context

export const useAside = () => useContext(AsideContext)