import React, { useEffect, useState } from 'react';
import './Task.scss';

// Components
import CustomModal from '../CustomModal/CustomModal';
import Subtask from '../Subtask/Subtask';
import TaskFormComponent from '../TaskFormComponent/TaskFormComponent';

// Images
import iconVerticalEllipsis from "../../assets/icon-vertical-ellipsis.svg"

// Contexts
import { useBoard } from '../../contexts/BoardContext';
import { useTheme } from '../../contexts/ThemeContext';

// API
import apiService from '../../services/apiService';


const Task = ({ columnId, taskId }) => {
  const { board, dispatch } = useBoard();
  const { theme } = useTheme();

  const [task, setTask] = useState(board.columns.flatMap(column => column.tasks).find(task => task.id === taskId))
  const [subtasks, setSubtasks] = useState(task.subtasks)
  const [completedSubtasks, setCompletedSubtasks] = useState(0);

  useEffect(() => {
    const foundTask = board.columns.flatMap(column => column.tasks).find(task => task.id === taskId);
    setTask(foundTask);
  }, [board.columns, taskId])

  useEffect(() => {
    const fetchAndSetCompletedSubtasks = async () => {
      try {
        const count = await apiService.countCompletedSubtasks(task.id);
        setCompletedSubtasks(count.completed_count);
      } catch (error) {
        console.error("Failed to count completed subtasks:", error);
      }
    };

    fetchAndSetCompletedSubtasks();
  }, [board.columns, task]);


  // Modal
  const [show, setShow] = useState(false);
  const [modalType, setModalType] = useState(null);

  const handleToggleModal = (type) => {
    if (modalType === type) {
      setModalType(null); // if the modal is already open, close it
    } else {
      setModalType(type); // otherwise, open the specified modal
    }
  };

  const handleChangeColumn = async (columnId) => {
    const response = await apiService.updateTask(task.id, { column_id: Number(columnId) });

    if (response.column_id == columnId) {
      dispatch({
        type: 'CHANGE_TASK_COLUMN',
        payload: { taskId: Number(task.id), columnId: Number(columnId) }
      })
    }
  }

  const handleDeleteTask = async () => {
    const taskData = await apiService.deleteTask(task.id);
    if (taskData.message === "Task deleted successfully") {
      dispatch({
        type: 'DELETE_TASK',
        payload: { id: taskId, column_id: columnId }
      })
      handleToggleModal('currentTask')
    }
  }

  return (
    <>

      <div className={`Task ${theme} rounded flex flex-column align-content-center pt-1 px-3 p-lg-4 mb-3`} onClick={() => handleToggleModal('currentTask')}>
        <h5 className={`${theme} mb-0`}>{task.title}</h5>
        <p className='mb-0'>{completedSubtasks} of {task.subtasks.length} {task.subtasks.length > 1 ? "subtasks" : "subtask"}</p>
      </div >

      {/* Task Modal */}
      <CustomModal

        show={modalType === 'currentTask'}
        key={task.id}
        onHide={() => handleToggleModal('currentTask')}
      >
        <div className="container">
          <div className="row">
            <div className="col-11">
              <h5 className={`${theme}`}>{task.title}</h5>
            </div>
            <div className="col-1">
              <div className="dropdown">
                <img src={iconVerticalEllipsis} className="img-fluid dropdown-toggle" alt="" type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                <ul className="dropdown-menu dropstart">
                  <li className='list-style-none'><span className="dropdown-item" onClick={() => handleToggleModal('editTask')}>Edit Task</span></li>
                  <li className='list-style-none'><span className="dropdown-item text-danger" onClick={() => handleDeleteTask()}>Delete Task</span></li>
                </ul>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <p>{task.description}</p>
                <p className='mb-0 fw-bold'>{task.subtasks.length > 1 ? "Subtasks" : "Subtask"} ({completedSubtasks} of {task.subtasks.length})</p>
                {task && task.subtasks.length > 0 && task.subtasks.map((subtask) => (
                  <Subtask subtaskId={subtask.id} completedSubtasks={completedSubtasks} key={subtask.id} />
                ))}
                <div className='mt-3'>
                  <label className="form-label fw-bold">Current Status</label>
                  <select className="form-select mb-3" aria-label="Current status"
                    onChange={(event) => handleChangeColumn(event.target.value)}
                    value={task.column_id}
                  >
                    {board.columns.map((column) =>
                      <option value={column.id} key={column.id}>{column.name}</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

      </CustomModal>

      {/* Edit Modal */}
      <CustomModal
        title="Edit task"
        show={modalType === 'editTask'}
        onHide={() => handleToggleModal('editTask')}
      >
        <TaskFormComponent
          modalProps={{ onClose: handleToggleModal }}
          existingTask={task} isEditingMode={true} />
      </CustomModal>
    </>

  )
};

export default Task;