import React, { createContext, useContext, useEffect, useReducer } from "react";
import apiService from "../services/apiService";


// Initial state of the APP
const initialState = {
    board: null,
}

// Define the reducer function
const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_BOARD':
            return { ...state, board: action.payload };
        case 'TOGGLE_SUBTASK_COMPLETION':
            return {
                ...state,
                board: {
                    ...state.board,
                    columns: state.board.columns.map(column => ({
                        ...column,
                        tasks: column.tasks.map(task => ({
                            ...task,
                            subtasks: task.subtasks.map(subtask =>
                                subtask.id === action.payload.subtaskId ?
                                    {
                                        ...subtask,
                                        completed: !subtask.completed // Assuming you want to toggle the completion status
                                    }
                                    : subtask
                            ),
                        })),
                    })),
                },
            };


        case 'CHANGE_TASK_COLUMN':
            // Find and remove the task
            let taskToMove;
            const updatedColumns = state.board.columns.map(column => {
                const filteredTasks = column.tasks.filter(task => {
                    if (task.id === action.payload.taskId) {
                        taskToMove = task;
                        return false; /// Remove task from column
                    }
                    return true;
                });
                return { ...column, tasks: filteredTasks }
            })

            // Add the task to new column
            const columnsWithUpdatedTask = updatedColumns.map(column => {
                if (column.id === action.payload.columnId) {
                    return { ...column, tasks: [...column.tasks, { ...taskToMove, column_id: action.payload.columnId }] };
                }
                return column;
            });
            return {
                ...state,
                board: {
                    ...state.board,
                    columns: columnsWithUpdatedTask
                }
            };
        case 'UPDATE_BOARD_COLUMNS_NAME':
            // Map through the columns and update the matching column
            const editedColumns = state.board.columns.map(column => {
                if (column.id === action.payload.id) {
                    return { ...column, name: action.payload.name }; // Return updated column
                }
                return column; // Return unchanged column
            });

            // Return the updated state with the modified columns
            return {
                ...state,
                board: {
                    ...state.board,
                    columns: editedColumns,
                },
            };
        case 'ADD_NEW_COLUMN_TO_BOARD':
            return {
                ...state,
                board: {
                    ...state.board,
                    columns: [
                        ...state.board.columns, action.payload
                    ]
                }
            }
        case 'DELETE_COLUMN_FROM_BOARD':
            const filteredColumns = state.board.columns.filter(column => {
                if (column.id === action.payload) {
                    return false;
                }
                return true;
            })
            return {
                ...state,
                board: {
                    ...state.board,
                    columns:
                        filteredColumns
                }
            }
        case 'DELETE_TASK':
            const { id: taskToDeleteID, column_id: columnToDeleteFromId } = action.payload;

            const updatedColumnsForTaskDeletion = state.board.columns.map(column => {
                if (column.id !== columnToDeleteFromId) {
                    return { ...column };
                }
                const filteredTasks = column.tasks.filter(task => task.id !== taskToDeleteID);
                return { ...column, tasks: filteredTasks };
            });

            const updatedStateAfterDeletion = {
                ...state,
                board: {
                    ...state.board,
                    columns: updatedColumnsForTaskDeletion,
                }
            };
            return updatedStateAfterDeletion;

        case 'UPDATE_TASK':
            const { title: newTitle, id: taskToEditID, description: newDescription, column_id: columnToEditId } = action.payload;

            const updatedColumnsForTaskChange = state.board.columns.map(column => {
                if (column.id !== columnToEditId) {
                    return { ...column };
                }
                const updatedTask = column.tasks.map(task => {
                    if (task.id === taskToEditID) {
                        const updatedTask = { ...task, title: newTitle, description: newDescription };
                        return updatedTask;
                    }
                    return { ...task };
                });
                return { ...column, tasks: updatedTask };
            });

            const updatedState = {
                ...state,
                board: {
                    ...state.board,
                    columns: updatedColumnsForTaskChange,
                }
            };

            return updatedState;

        case 'UPDATE_SUBTASK':
            const { title: newSubtaskTitle, id: subtaskId, task_id: taskId } = action.payload;

            const updatedColumnsForSubtaskChange = state.board.columns.map(column => {
                const updatedTasks = column.tasks.map(task => {
                    if (task.id !== taskId) {
                        return task;
                    }
                    const updatedSubtasks = task.subtasks.map(subtask => {
                        if (subtask.id !== subtaskId) {
                            return subtask;
                        }
                        return { ...subtask, title: newSubtaskTitle };
                    });
                    return { ...task, subtasks: updatedSubtasks }
                })
                return { ...column, tasks: updatedTasks }
            });

            return {
                ...state,
                board: {
                    ...state.board,
                    columns: updatedColumnsForSubtaskChange
                }
            }

        case 'ADD_NEW_SUBTASK':
            const updatedColumnsForNewSubtask = state.board.columns.map(column => {
                const updatedTasks = column.tasks.map(task => {
                    if (task.id !== action.payload.task_id) {
                        return task;
                    }
                    const updatedSubtasks = [...task.subtasks, action.payload];
                    return { ...task, subtasks: updatedSubtasks }
                });
                return { ...column, tasks: updatedTasks }
            });
            return {
                ...state,
                board: {
                    ...state.board,
                    columns: updatedColumnsForNewSubtask
                }
            }

        case 'DELETE_SUBTASK':

            const updatedColumnsForSubtaskDeletion = state.board.columns.map(column => {
                const updatedTasks = column.tasks.map(task => {
                    if (task.id !== action.payload.task_id) {
                        return task;
                    }
                    const updatedSubtasks = task.subtasks.filter(subtask => {
                        if (subtask.id === action.payload.id) {
                            return false;
                        }
                        return subtask;
                    });
                    return { ...task, subtasks: updatedSubtasks }
                })
                return { ...column, tasks: updatedTasks }
            });

            return {
                ...state,
                board: {
                    ...state.board,
                    columns: updatedColumnsForSubtaskDeletion
                }
            }

        default:
            return state;
    }

};

// Create the context
export const BoardContext = createContext();

// Create the provider component
const BoardProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const fetchFirstBoard = async () => {
            const data = await apiService.getFirstBoard();
            dispatch({ type: 'SET_BOARD', payload: data });
        };
        fetchFirstBoard();
    }, []);

    return (
        <BoardContext.Provider value={{ board: state.board, dispatch }}>
            {children}
        </BoardContext.Provider >
    );
}

export default BoardProvider;

// Custom use hook
export const useBoard = () => useContext(BoardContext);