import React, { useState } from 'react';
import "./HeaderComponent.scss";

// API
import apiService from '../../services/apiService';

// Images
import iconVerticalEllipsis from "../../assets/icon-vertical-ellipsis.svg"

// Contexts
import { useTheme } from '../../contexts/ThemeContext';
import { useBoard } from '../../contexts/BoardContext';
import { useAside } from '../../contexts/AsideContext';

// Other components
import CustomModal from '../CustomModal/CustomModal';
import TaskFormComponent from '../TaskFormComponent/TaskFormComponent';
import BoardFormComponent from '../BoardFormComponent/BoardFormComponent';


const HeaderComponent = () => {
  const { theme } = useTheme();
  const { board, dispatch: boardDispatch } = useBoard();
  const { boards, dispatch: asideDispatch } = useAside();

  const [modalType, setModalType] = useState(null);

  const handleDeleteBoard = async () => {

    const response = await apiService.deleteBoard(board.id);
    if (response && response.message === "Board deleted successfully") {
      asideDispatch({ type: 'DELETE_BOARD_FROM_ASIDE', payload: board.id });
      if (board.id === boards[0].id) {
        boardDispatch({ type: 'SET_BOARD', payload: boards[1] })
      } else {
        boardDispatch({ type: 'SET_BOARD', payload: boards[0] })
      }
      setModalType(null)
    } else {
      setModalType("errorModal")
    }

  }

  const handleToggleModal = (type) => {

    if (modalType === type) {
      setModalType(null); // if the modal is already open, close it
    } else {
      setModalType(type); // otherwise, open the specified modal
    }
  };

  return (
    <div className={`Header ${theme} d-flex justify-content-between align-items-center p-5`}>
      <h1>{board ? board.name : ""}</h1>
      <div>
      </div>
      <div className="d-flex align-items-center">
        <button className='btn button-primary px-4 py-2'>
          <span className='d-none d-sm-block' onClick={() => handleToggleModal('addTask')}>+ Add New Task</span>
          <span className='d-block d-sm-none h5' onClick={() => handleToggleModal('addTask')}>+</span>
        </button>

        <div className="dropdown">
          <img src={iconVerticalEllipsis} className="ms-5 img-fluid dropdown-toggle" alt="" type="button" data-bs-toggle="dropdown" aria-expanded="false" />
          <ul className="dropdown-menu">
            <li><span className="dropdown-item" onClick={() => handleToggleModal('editBoard')}>Edit Board</span></li>
            <li><span className="dropdown-item text-danger" onClick={() => handleToggleModal('deleteBoard')}>Delete Board</span></li>
          </ul>
        </div>
      </div>

      <CustomModal
        show={modalType === 'deleteBoard'}
        onHide={() => handleToggleModal('deleteBoard')}
        title="Delete this board?"
      >
        <p>Are you sure you want to delete the "<b>{board && board.name}</b>" board? This action will remove all columns and tasks and cannot be reversed.</p>
        <div className="d-flex justify-content-center align-items-center">
          <button className="btn btn-danger px-5 py-2" type="button" onClick={() => handleDeleteBoard('deleteBoard')}>Delete</button>
          <button className="btn btn-secondary px-5 py-2 ms-2" type="button" onClick={() => handleToggleModal('deleteBoard')}>Cancel</button>
        </div>
      </CustomModal>

      <CustomModal
        show={modalType === 'errorModal'}
        onHide={() => handleToggleModal('errorModal')}
        title="Error when deleting the modal"
      >

        <p>There has been an error while trying to delete "<b>{board && board.name}</b>" board. Please try again later.</p>
        <div className="d-flex justify-content-center align-items-center">
          <button className="btn btn-secondary px-5 py-2 ms-2" type="button" onClick={() => handleToggleModal('errorModal')}>Cancel</button>
        </div>
      </CustomModal>

      <CustomModal
        show={modalType === 'addTask'}
        onHide={() => handleToggleModal('addTask')}
        title="Add New Task"
      >
        <TaskFormComponent modalProps={{ handleToggleModal }} />
      </CustomModal>

      <CustomModal
        show={modalType === 'editBoard'}
        onHide={() => handleToggleModal('editBoard')}
        title="Edit Current Board"
      >
        <BoardFormComponent
          handleToggleModal={{ handleToggleModal }}
          modalProps={{ onClose: handleToggleModal }}

          existingBoard={board} isEditingMode={true}
        />
      </CustomModal>

    </div>
  )
};

export default HeaderComponent;
