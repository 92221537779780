import React, { useState } from 'react';
import Column from '../components/Column/Column';
import { useBoard } from '../contexts/BoardContext';
import "../App.scss";
import { useTheme } from '../contexts/ThemeContext';
import CustomModal from '../components/CustomModal/CustomModal';
import BoardFormComponent from '../components/BoardFormComponent/BoardFormComponent';



const HomeView = () => {

  const { board } = useBoard();
  const { theme } = useTheme();

  const [modalType, setModalType] = useState(null);
  const handleToggleModal = (type) => {

    if (modalType === type) {
      setModalType(null); // if the modal is already open, close it
    } else {
      setModalType(type); // otherwise, open the specified modal
    }
  };


  return (
    <div className='container-fluid h-100'>
      <div className="row flex-nowrap overflow-auto h-100">
        {board && board.columns.length > 0 ? board.columns.map((column) =>
          <div key={column.id} className="col column-container">
            <Column columnId={column.id} />
          </div>
        ) :
          `There are no boards yet, create the first one using the button on the menu on the left.`}
        {/*Extra column*/}
        <div className={`${theme} col column-container d-flex justify-content-center align-items-center rounded mb-5`}>
          <div className="Column mt-2 text-center h-100">
            <h5>&nbsp;</h5>
            <div className={`Task ${theme} rounded flex flex-column align-content-center p-4 mb-3 h-100 extra-task`} onClick={() => handleToggleModal('editBoard')}>
              <h5 className={`${theme}`}>+ New Column</h5>
            </div >
          </div>
        </div>
        {/*End Extra column*/}
      </div>
      <CustomModal
        show={modalType === 'editBoard'}
        onHide={() => handleToggleModal('editBoard')}
        title="Edit Current Board"
      >
        <BoardFormComponent
          handleToggleModal={{ handleToggleModal }}
          modalProps={{ onClose: handleToggleModal }}

          existingBoard={board} isEditingMode={true}
        />
      </CustomModal>
    </div >
  );
};

export default HomeView;