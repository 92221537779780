import React, { useEffect, useState } from 'react';
import './Subtask.scss';
import { useTheme } from '../../contexts/ThemeContext';
import apiService from '../../services/apiService';

import { useBoard } from '../../contexts/BoardContext';



const Subtask = ({ subtaskId }) => {
  const { theme } = useTheme();

  const { board, dispatch } = useBoard();

  const [subtask, setSubtask] = useState(board.columns
    .flatMap(column => column.tasks)
    .flatMap(task => task.subtasks)
    .find(subtask => subtask.id === subtaskId));

  const [completitionStatus, setCompletitionStatus] = useState(subtask.completed);



  const handleToggleSubtaskCompletition = async () => {
    const newCompletitionStatus = !completitionStatus;
    setCompletitionStatus(newCompletitionStatus);
    const updatedSubstak = await apiService.updateSubtask(subtask.id, { "completed": newCompletitionStatus });
    if (!!updatedSubstak.completed === !!newCompletitionStatus) {
      dispatch({
        type: 'TOGGLE_SUBTASK_COMPLETION',
        payload: { subtaskId: subtask.id, completed: completitionStatus }
      })
    }
  }

  return (
    <div className={`Subtask ${theme} px-4 py-3 rounded mt-1`}>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id={`check${subtask.id}`}
          checked={completitionStatus ? true : false}
          onChange={() => handleToggleSubtaskCompletition()}
        />
        <label className={`form-check-label ${completitionStatus ? "text-decoration-line-through" : ""}`} htmlFor={`check${subtask.id}`}>
          {subtask.title}
        </label>
      </div>
    </div >
  )
};

export default Subtask;