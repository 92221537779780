import React, { useState } from 'react';

// Styles
import "./AsideComponent.scss";


// Subcomponent
import AsideMenuComponent from './AsideMenuComponent/AsideMenuComponent';
import CustomModal from '../CustomModal/CustomModal';

// Images
import logoLight from '../../assets/logo-light.svg';
import logoDark from '../../assets/logo-dark.svg';
import logoMobile from '../../assets/logo-mobile.svg';

import iconShowSidebar from '../../assets/icon-show-sidebar.svg';
import iconHideSidebar from "../../assets/icon-hide-sidebar.svg"

// Context
import { useAside } from '../../contexts/AsideContext';
import { useTheme } from '../../contexts/ThemeContext';

const AsideComponent = () => {
  const { theme } = useTheme();
  const { isCollapsed, toggleCollapse } = useAside();




  const logo = theme === 'light' ? logoDark : theme === 'dark' ? logoLight : logoMobile;

  return (
    <>

      <div className={`Aside col-sm-2 col-auto px-0 h-100`}>
        <div className="list-group border-0 min-vh-100 h-100 py-4 px-0" id="Aside-menu">
          <img src={logo} className="img-fluid w-75 mx-2 mb-5" alt="Logo" />
          <AsideMenuComponent />
          <div className='mt-5 pe-2 d-flex flex-column'>
            <div className={`${theme} mx-2 fw-bold`} onClick={toggleCollapse} style={{ cursor: "pointer" }}>
              <img src={iconHideSidebar} className='mx-4' alt="Hide Sidebar" />
              Hide Sidebar
            </div >
          </div>
        </div>
      </div>




    </>
  );
}

export default AsideComponent;
