import React, { useEffect } from 'react';
import './Column.scss';
import Task from '../Task/Task';
import { useBoard } from '../../contexts/BoardContext';

const Column = ({ columnId }) => {
  const { board } = useBoard();
  const column = board.columns.find(column => column.id === columnId);

  return (
    <div className="Column mt-2" key={column.id}>
      <h5 style={{ letterSpacing: "2.4px" }} className=''>
        {column.name} ({column && column.tasks.length})
      </h5>
      <ul>
        {column && column.tasks.length > 0 && column.tasks.map((task) =>
          <li key={task.id}><Task taskId={task.id} columnId={column.id} /></li>
        )}
      </ul>
    </div>
  );
};

export default Column;