import React, { useState } from 'react';

// Styles
import "./AsideMenuComponent.scss";

//useTheme custom hook
import { useTheme } from '../../../contexts/ThemeContext';
import { useBoard } from '../../../contexts/BoardContext';

// Images
import iconLightTheme from "../../../assets/icon-light-theme.svg"
import iconDarkTheme from "../../../assets/icon-dark-theme.svg"

// API Service
import apiService from '../../../services/apiService';

// Other Components
import CustomModal from '../../CustomModal/CustomModal';
import BoardFormComponent from '../../BoardFormComponent/BoardFormComponent'

// ASIDE CONTEXT
import { useAside } from '../../../contexts/AsideContext';



const AsideMenuComponent = (props) => {

    // Context for the aside
    // renaming syntax, assigns the dispatch property to a new variable named boardsDispatch
    const { boards, dispatch: boardsDispatch, toggleCollapse } = useAside();
    // Context for board
    const { board, dispatch: boardDispatch } = useBoard();

    // const [data, setData] = useState([])
    const { theme, handleToggleTheme } = useTheme();

    // MODAL
    const [show, setShow] = useState(false);
    const handleToggleModal = () => setShow(prevShow => !prevShow);


    const handleToggleBoard = async (board_id) => {
        const response = await apiService.getBoardById(board_id);
        boardDispatch({ type: 'SET_BOARD', payload: response })
        // Close the parent modal
        if (props.modal) {
            props.handleCloseParentModal();
        }
    }

    return (
        <>
            <h6 className="mb-4 mx-2 d-none d-sm-flex justify-content-between" style={{ letterSpacing: "2.4px" }}>
                All Boards ({boards && boards.length ? boards.length : "0"})
            </h6>
            <ul>
                {boards && boards.map((item) => <li className={`py-3 px-1 mt-1 ps-2 ${item.id === board?.id ? "active" : ""}`}
                    style={{ cursor: 'pointer' }}
                    key={item.id}
                    onClick={() => handleToggleBoard(item.id)}
                >{item.name}</li>)}
                <li className='py-3 px-1 mt-1 ps-2' onClick={handleToggleModal}>+ Create New Board</li>
            </ul>
            <div className={`${theme} aside-menu-bottom d-flex justify-content-center align-items-start p-3 m-2 mt-auto rounded`}>
                <div><img src={iconLightTheme} className='mx-4' alt="Light theme" /></div>
                <div className="form-check form-switch ms-2">
                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" defaultChecked onClick={handleToggleTheme} />
                </div>
                <div><img src={iconDarkTheme} className='mx-4' alt="Dark theme" /></div>
            </div>

            <CustomModal
                show={show}
                onHide={handleToggleModal}
                title="Create New Board"
            >
                <BoardFormComponent
                    modalProps={{ onClose: handleToggleModal }}
                    isEditingMode={false}
                    existingBoard={false}
                />
            </CustomModal>
        </>



    );
}

export default AsideMenuComponent;
