import Modal from 'react-bootstrap/Modal';
import { useTheme } from '../../contexts/ThemeContext';


// Styles
import "./CustomModal.scss";

function CustomModal({ title, children, show, onHide }) {
  const { theme } = useTheme();


  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="xs"

        // centered
        className={`Modal ${theme}`}>
        {title && (
          <Modal.Header closeButton>
            <Modal.Title className="modal-title">{title}</Modal.Title>
          </Modal.Header>
        )}

        <Modal.Body className='modal-body'>
          {children}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CustomModal;
