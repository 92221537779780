import React, { useState } from 'react';
import './BoardFormComponent.scss';

// API
import apiService from '../../services/apiService';

// CONTEXTS
import { useAside } from '../../contexts/AsideContext';
import { useTheme } from '../../contexts/ThemeContext';
import { useBoard } from '../../contexts/BoardContext';

// Components
import CustomModal from '../CustomModal/CustomModal';

// React Bootstrap
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


const BoardFormComponent = ({ modalProps, existingBoard, isEditingMode }) => {

  const { theme } = useTheme();
  const { dispatch } = useAside();
  const { dispatch: boardDispatch } = useBoard();

  const [columns, setColumns] = useState(isEditingMode ? existingBoard.columns : [{ "name": "" }])
  const [boardName, setBoardName] = useState(isEditingMode ? existingBoard.name : "");

  // With JSON.parse(JSON.stringify(existingBoard.columns) we create a hard copy of the columns, and we don't 
  // reference columns and editedColumns to the same destination in the memory 
  const [editedColumns, setEditedColumns] = useState(isEditingMode ? JSON.parse(JSON.stringify(existingBoard.columns)) : []);
  const [editedBoardName, setEditedBoardName] = useState(isEditingMode ? existingBoard.name : "");
  const [existingBoardId, setExistingBoardId] = useState(isEditingMode ? existingBoard.id : null);

  // Columns to delete ARRAY
  const [columnsToDelete, setColumnsToDelete] = useState([]);

  // Error
  const [error, setError] = useState("");

  // Modal
  const [show, setShow] = useState(false);
  const [columnInfo, setColumnInfo] = useState({})

  // Validation
  const validChars = (name) => {
    const regex = /^[a-zA-Z0-9 ]*$/;
    return regex.test(name);
  };

  const handleToggleModal = (columnInfo) => {
    setColumnInfo(columnInfo)
    setShow(prevStatus => !prevStatus);
  };

  // Actual HTML INPUT
  const handleAddInput = () => {
    setError("")
    if (!isEditingMode) {
      setColumns([...columns, { "name": "" }]);
    } else {
      setEditedColumns([...editedColumns, { "name": "" }]);
    }
  }

  // DELETE COLUMNS - input
  const handleDeleteInput = (name) => {
    if (!name) return;
    if (!isEditingMode) {
      if (columns.length === 1) {
        setError("You need to add, at least, *1* column in your board.")
      } else {
        setError("");
        const indexToDelete = columns.findIndex(input => input.name === name);
        if (indexToDelete !== -1) { //If it's found, because the lowest index is 0.
          const filteredArray = [...columns];
          filteredArray.splice(indexToDelete, 1);
          setColumns(filteredArray);
        }
      }
    } else {
      if (editedColumns.length === 1) {
        setError("You need to add, at least, *1* column in your board.")
      } else {
        setError("");
        const indexToDelete = editedColumns.findIndex(input => input.name === name);
        if (indexToDelete !== -1) { //If it's found, because the lowest index is 0.
          const filteredArray = [...editedColumns];
          filteredArray.splice(indexToDelete, 1);
          setEditedColumns(filteredArray);
          setColumnsToDelete(prevColumns => [...prevColumns, editedColumns[indexToDelete]])
          setShow(false)
        }
      }
    }

  }


  const handleInputChange = (event, index, inputValue) => {
    if (validChars(event.target.value)) {
      setError("")
      let updatedColumns;
      if (!isEditingMode) {
        updatedColumns = JSON.parse(JSON.stringify(columns));
      } else {
        updatedColumns = JSON.parse(JSON.stringify(editedColumns));

      }
      setError("");
      if (updatedColumns.some(input => input.name === inputValue)) {
        setError("There cannot be duplicated columns.");
      }
      updatedColumns[index].name = event.target.value;
      if (!isEditingMode) {
        setColumns(updatedColumns);
      } else {
        setEditedColumns(updatedColumns)
      }
    } else {
      setError("Columns name can only have letters, numbers and spaces.")
    }
  };

  // CHANGE BOARD NAME
  const handleSetBoardName = (value) => {
    if (validChars(value)) {
      setError("")
      if (!isEditingMode) {
        setBoardName(value);
      } else {
        setEditedBoardName(value)
      }
    } else {
      setError("Board name can only have letters, numbers and spaces.")
    }
  }

  const handleAddNewBoard = async () => {
    const boardData = await apiService.addNewBoard(boardName);
    if (boardData.id) {
      console.log(columns)
      const columnsWithBoardId = columns.map(column => ({ ...column, "board_id": boardData.id }));
      for (const column of columnsWithBoardId) {
        const columnData = await apiService.addNewColumns(column);
        console.log(columnData)
      }
      const setBoard = await apiService.getBoardById(boardData.id);
      if (setBoard.id) {
        boardDispatch({ type: 'SET_BOARD', payload: setBoard });
        dispatch({ type: 'UPDATE_ASIDE_WITH_BOARD_NAMES', payload: setBoard });
      }


      modalProps.onClose()
    }
  }

  const handleEditBoard = async () => {

    //Edit Board Name
    if (boardName !== editedBoardName) {
      const boardData = await apiService.editExistingBoard(existingBoardId, editedBoardName);
      if (boardData.id) {
        dispatch({ type: 'UPDATE_ASIDE_WITH_NEW_BOARD_NAME', payload: boardData });
        boardDispatch({ type: 'SET_BOARD', payload: boardData });
      }
    }

    // Add columns
    for (let i = 0; i < editedColumns.length; i++) {
      if (!editedColumns[i].id) {
        const columnData = await apiService.addNewColumns({ "board_id": existingBoard.id, "name": editedColumns[i].name });
        if (columnData.id) {
          boardDispatch({ type: 'ADD_NEW_COLUMN_TO_BOARD', payload: columnData });
        }
      }
    }

    // Delete Columns
    if (columnsToDelete.length > 0) {
      for (const column of columnsToDelete) {
        const columnData = await apiService.deleteColumn(column.id);
        if (columnData.message === "Column deleted successfully") {
          boardDispatch({ type: 'DELETE_COLUMN_FROM_BOARD', payload: column.id });
        }
      }
    }

    // Edit Columns Name

    for (const editedColumn of editedColumns) {
      const columnToCompare = columns.find(column => column.id === editedColumn.id);
      if (columnToCompare && editedColumn.name !== columnToCompare.name) {
        const columnData = await apiService.editExistingColumn(editedColumn.id, editedColumn.name);
        if (columnData.id) {
          boardDispatch({ type: 'UPDATE_BOARD_COLUMNS_NAME', payload: columnData });
        }
      }
    }

    modalProps.onClose()

  }

  return (
    <div className={`AddNewBoardForm ${theme} d-flex flex-column`}>
      <div className="mb-3">
        <label htmlFor="boardName" className="form-label fw-bold">Board Name</label>
        <input type="text" className="form-control" id="boardName" placeholder="e.g. Web Design"
          value={isEditingMode ? editedBoardName : boardName}
          onChange={(event) => handleSetBoardName(event.target.value)}
        />
      </div>
      <p className="fw-bold">Columns</p>
      {
        (isEditingMode ? editedColumns : columns).map((input, index) => {
          return (
            < div className="input-group mb-3" key={index} >
              <input type="text" className="form-control"
                value={input.name}
                onChange={(event) => handleInputChange(event, index, event.target.value)}
              />
              <span className="btn" type="button"
                onClick={!isEditingMode ? () => handleDeleteInput(input.name) : () => handleToggleModal(input)}
              >X</span>
            </div >
          )
        })}
      {
        error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )
      }

      <button className={`${theme} btn button-secondary mb-2`} onClick={handleAddInput}>+ Add New Column</button>

      <button className='btn button-primary'
        // onClick={isEditingMode ? () => handleEditBoard() : () => handleAddNewBoard()}
        // disabled={error !== "" || !boardName || !columns[0].name}
        onClick={() => {
          if (error !== "" || !boardName || !columns[0].name) {
            setError("You need to add a board name and, at least, one column.");
          } else {
            isEditingMode ? handleEditBoard() : handleAddNewBoard();
          }
        }}

      >
        {!isEditingMode ? "Add New Board" : "Edit Current Board"}
      </button>

      <CustomModal
        show={show}
        onHide={() => handleToggleModal(false)}
        title="Delete this board?"
      >
        <p>Are you sure you want to delete the "<b>{columnInfo && columnInfo.name}</b>" colummn? This action will remove all tasks and subtasks and cannot be reversed.</p>
        {columnInfo.tasks && columnInfo.tasks.length > 0 ? "These are the tasks associated to this column:t" : ""}
        <ul>
          {columnInfo.tasks && columnInfo.tasks.length > 0 && columnInfo.tasks.map((task, index) => (
            <li className="list-style-none" key={index}>
              - {task.title}
            </li>
          ))}
        </ul>
        <div className="d-flex justify-content-center align-items-center">
          <button className="btn btn-danger px-5 py-2" type="button" onClick={() => handleDeleteInput(columnInfo.name)}>Delete</button>
          <button className="btn btn-secondary px-5 py-2 ms-2" type="button" onClick={() => handleToggleModal(false)}>Cancel</button>
        </div>
      </CustomModal>
    </div >
  )
};

export default BoardFormComponent;
